<template>
  <div id="page-overlay"
    class="absolute bg-beige duration-300 ease-in h-screen left-0 opacity-0 pointer-events-none top-0 transition-opacity w-screen z-10 md:hidden"
    style="background-image: url('/lines_thick.svg')"
    v-bind:class="{
      'opacity-95 pointer-events-auto': chatOpen || pageOpen
    }"
    v-on:click="$router.push({query: {}})">
  </div>
</template>

<script>
export default {
  computed: {
    chatOpen () {
      return this.$route.query.chat !== undefined
    },
    pageOpen () {
      return this.$route.query.page !== undefined
    }
  }
}
</script>
