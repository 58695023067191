export default {
  namespaced: true,
  state: {
    members: []
  },
  mutations: {
    setMembers(state, payload) {
      state.members = payload
    }
  },
  actions: {}
}
