<template>
  <div id="auth-password-form"
    class="absolute pt-10 top-0 w-full"
    v-bind:class="{
      moved: page === 'email'
    }">

    <div class="px-5">
      {{email}}

      <div v-if="authMethodsForEmail.length > 0">
        <input type="password"/>
      </div>

      <div v-else>
        <input type="password" />
        <input type="password" />
      </div>

      <button class="block bg-white border border-gray-300 px-3 py-1 rounded-md text-red-500"
        v-on:click="$emit('back')">back</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    email: String,
    authMethodsForEmail: Array,
    page: String
  }
}
</script>

<style lang="sass" scoped>
#auth-password-form
  left: 100vw
  transform: translate3d(0, 0, 0)
  transition: all 0.3s ease-in-out
  will-change: transform

  &.moved
    transform: translate3d(-100vw, 0, 0)
</style>
