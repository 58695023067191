<template>
  <div id="chat-page"
    class="bg-beige fixed h-screen w-screen z-20"
    v-bind:class="{
      open: open
    }">

    <div id="chat-head" class="bg-white border-b border-gray-200 flex h-7 relative w-full z-20">
      <div class="flex-none left-0 p-2 w-9 md:hidden"
        v-on:click="close()">
        <img class="mt-0.5" src="@/assets/icons/Back.svg" />
      </div>

      <div class="flex-grow mt-1.5 text-center text-gray-400 md:pl-1.5 md:text-left" v-if="user">
        <div class="bg-gray-100 border border-gray-300 h-4 inline-block mr-1.5 rounded-xl w-4"></div>
        <div class="align-top inline-block mr-1 mt-0.5">{{user.displayName}}</div>
      </div>

      <div class="flex-none w-9" />
    </div>

    <ChatMessages />

    <ChatInput />
  </div>
</template>

<script>
import ChatInput from '@/components/Chat/Input'
import ChatMessages from '@/components/Chat/Messages'
import { db } from '@/firebase/app'

import { Plugins } from '@capacitor/core'
const { Keyboard } = Plugins

export default {
  components: {
    ChatInput,
    ChatMessages
  },
  computed: {
    person () {
      const chatId = this.$route.query.chat

      if (chatId === undefined) {
        return null
      }

      if (this.$store.state.Auth.user === undefined) {
        return null
      }

      const peopleIds = chatId.split('-')[1].split('_and_')
      if (peopleIds[0] !== this.$store.state.Auth.user.uid) {
        return peopleIds[0]
      } else {
        return peopleIds[1]
      }
    },
    open () {
      return this.$route.query.chat !== undefined
    }
  },
  data() {
    return {
      user: null
    }
  },
  methods: {
    close () {
      this.$router.push({
        query: {}
      })
    },
    verifyChatOpen () {
      const chat = this.$route.query.chat
      if (chat !== undefined) {
        Keyboard.setAccessoryBarVisible({ isVisible: false })
      } else {
        Keyboard.setAccessoryBarVisible({ isVisible: false })
      }

      if (this.person !== null) {
        this.$bind('user', db.collection('users').doc(this.person))
      }
    }
  },
  mounted () {
    this.verifyChatOpen()
  },
  watch: {
    '$route.query.chat': {
      handler: function () {
        this.verifyChatOpen()
      }
    },
    person (newVal) {
      if (newVal === null) {
        this.$unbind('user')
        this.user = null
        return
      }
      this.$bind('user', db.collection('users').doc(newVal))
    }
  }
}
</script>

<style lang="sass" scoped>
#chat-page
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25)
  height: 100vh
  height: calc(100vh - env(safe-area-inset-top))
  left: calc(100vw + 40px)
  top: 0
  top: env(safe-area-inset-top)
  transform: translate3d(0, 0, 0)
  transition: all 0.4s ease

  &.open
    transform: translate3d(calc(-100vw - 40px), 0, 0)

@media (min-width: 768px)
  #chat-page
    box-shadow: none
    display: none
    top: 0
    transform: translate3d(calc(-100vw + 341px), 0, 0)
    width: calc(100vw - 380px)

    &.open
      display: block
      transform: translate3d(calc(-100vw + 341px), 0, 0)
</style>
