<template>
  <div>
    <JoinCourse />

    <Profile />

    <div id="modal-overlay"
      class="absolute bg-beige duration-300 ease-in h-screen left-0 opacity-0 pointer-events-none top-0 transition-opacity w-screen z-50"
      style="background-image: url('/lines_thick.svg')"
      v-bind:class="{
        'opacity-95 pointer-events-auto': modalOpen
      }"
      v-on:click="$router.push({query: {}})">
    </div>
  </div>
</template>

<script>
import JoinCourse from './JoinCourse/index'
import Profile from './Profile'

export default {
  components: {
    JoinCourse,
    Profile
  },
  computed: {
    modalOpen () {
      return this.$route.query.modal !== undefined
    }

  }
}
</script>

<style lang="sass">
.modal
  border-radius: 10px 10px 0 0
  top: 100vh
  transform: translate3d(0, 0, 0)
  transition: transform 0.3s ease
  z-index: 60
  /* stylelint-disable-next-line */
  @apply bg-white fixed rounded-md shadow-xl w-full
</style>
