<template>
  <div class="pt-2.5 px-2">
    <div class="relative">
      <div class="font-bold text-xl">Create a Course</div>
      <div class="absolute -right-1 -top-1"
        v-on:click="$router.push({query: {}})">
        <img src="@/assets/icons/Close.svg" class="h-6 p-2 pr-3 w-7" />
      </div>

      <div class="pointer-events-none text-gray-400 text-sm">Add a new course, for a new group of students or maybe a new subject you’ll start teaching</div>
    </div>

    <div class="mt-5">
      <div class="font-bold">Course Title</div>
      <input class="rounded-md"
        type="text"
        placeholder="Mathematics 101"
        v-model="courseName">
    </div>

    <div class="mt-2.5">
      <div class="font-bold">Group</div>
      <input class="rounded-md"
        type="text"
        placeholder="9-B"
        v-model="group">
    </div>

    <div class="mt-2.5">
      <div class="font-bold">Default Color</div>
      <div>
        <div class="h-6 inline-block mr-1.5 opacity-20 rounded-md w-6"
          v-for="(option, index) in colorOptions"
          v-bind:key="index"
          v-bind:class="{
            'opacity-100': option === colorSelected
          }"
          v-on:click="colorSelected = option"
          v-bind:style="{
            backgroundColor: option
          }"></div>
      </div>
    </div>

    <div class="mb-1 mt-4 text-gray-400 text-justify text-sm">Creating this course will take you directly to it to invite people and add contents</div>
    <button id="create-course-button"
      class="bg-blue-500 block h-6 mb-2 ml-auto px-1.5 rounded-md text-left text-white"
      v-on:click="createCourse()">
      Create Course <img class="float-right mt-1" src="@/assets/icons/Arrow_right_white.svg" />
    </button>
  </div>
</template>

<script>
import { db } from '@/firebase/app'
import makeID from '@/utils/makeID'

export default {
  data() {
    return {
      colorSelected: '#219653',
      colorOptions: [
        '#219653', '#2F80ED', '#9B51E0', '#EB5757', '#F2994A', '#333333'
      ],
      courseName: null,
      group: null
    }
  },
  methods: {
    async createCourse () {
      const self = this
      const loggedinUserID = this.$store.state.Auth.user.uid
      const id = makeID(6)
      console.log(id)

      const courseRef = db.collection('courses').doc(id)
      const doc = await courseRef.get()
      if (!doc.exists) {
        console.log('Safe to go, course with that ID does not exists')
      } else {
        return ''
      }

      db.collection('courses').doc(id).set({
        created_at: new Date(),
        updated_at: new Date(),
        default_color: this.colorSelected,
        group: this.group,
        member_privileges: {
          [loggedinUserID]: 'professor'
        },
        members: [loggedinUserID],
        owner: loggedinUserID,
        title: this.courseName
      })
        .then(() => {
          const newQuery = { ...self.$route.query }
          delete newQuery.modal
          self.$router.push({
            path: '/course/' + id,
            query: newQuery
          })
        })
        .catch((error) => {
          console.error('Error writing document: ', error)
        })
    }
  }
}
</script>

<style scoped lang="sass">
#create-course-button
  width: 208px
</style>
