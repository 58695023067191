<template>
  <div id="app"
    class="absolute font-sans h-screen top-0"
    v-bind:class="{
      moved: $route.query.sidebar === 'open'
    }">
    <SideBar />

    <router-view/>

    <ChatView />
    <DeviceEvents />
    <KeyboardEvents />
    <LoadingPage />
    <AuthPage />
    <PageOverlay />
    <PushNotifications />
    <Modals />
  </div>
</template>

<script>
import { firebase } from '@/firebase/app'

// Views
import AuthPage from '@/views/Auth/index'
import ChatView from '@/views/Chat'
import DeviceEvents from '@/components/DeviceEvents'
import KeyboardEvents from '@/components/KeyboardEvents'
import LoadingPage from '@/views/LoadingPage'
import Modals from '@/views/Modals/index'
import PageOverlay from '@/components/PageOverlay'
import PushNotifications from '@/components/PushNotifications'
import SideBar from '@/components/SideBar'

import {
  Plugins,
  StatusBarStyle
} from '@capacitor/core'

const { Keyboard, StatusBar } = Plugins

export default {
  components: {
    AuthPage,
    ChatView,
    DeviceEvents,
    KeyboardEvents,
    LoadingPage,
    Modals,
    PageOverlay,
    PushNotifications,
    SideBar
  },
  computed: {
    deviceInfo () {
      return this.$store.state.UI.device.info
    }
  },
  mounted () {
    this.$store.commit('UI/loadDeviceInfo')

    const self = this
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        StatusBar.setStyle({
          style: StatusBarStyle.Dark
        })
        self.$store.dispatch('Auth/loadUser', user)
      } else {
        StatusBar.setStyle({
          style: StatusBarStyle.Light
        })
        self.$store.dispatch('Auth/removeUser', null)
      }
    })
  },
  watch: {
    deviceInfo: {
      deep: true,
      handler: function () {
        if (this.deviceInfo !== undefined && this.deviceInfo.platform !== 'web') {
          Keyboard.setResizeMode('none')
          Keyboard.setAccessoryBarVisible({ isVisible: true })
        }
      }
    }
  }
}
</script>
<style lang="sass">
/* stylelint-disable-line */
@import "./styles/main"

#app
  max-width: 100vw
  transform: translate3d(0, 0, 0)
  transition: transform 0.3s ease
  width: 100vw
  will-change: transform

  &.moved
    transform: translate3d(80px, 0, 0)
</style>
