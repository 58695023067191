import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
// import 'firebase/functions'
import 'firebase/storage'

import credentials from './credentials'

firebase.initializeApp(credentials.config)

// utils
const db = firebase.firestore()
const auth = firebase.auth()
const storage = firebase.storage()

/* if (window.location.hostname === 'localhost') {
  firebase.functions().useFunctionsEmulator('http://localhost:5001')
} */

// export utils/refs
export { auth, db, storage, firebase }
