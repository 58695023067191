<template>
  <div class="bg-beige duration-300 ease-in fixed h-screen left-0 pt-safe transition top-0 delay-100 w-screen z-70"
    id="loading-page"
    v-bind:class="{
      'opacity-0 pointer-events-none': hide
    }">
    <Loader />
  </div>
</template>

<script>
import Loader from '@/components/Loader'

export default {
  components: {
    Loader
  },
  computed: {
    hide () {
      const knowsAuthState = this.$store.state.Auth.user !== undefined
      const knowsDeviceInfo = this.$store.state.UI.device.info !== undefined

      if (knowsAuthState && knowsDeviceInfo) {
        return true
      }

      return false
    }
  }
}
</script>
