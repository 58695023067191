import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/course/:course_id',
    name: 'Course',
    component: () =>
      import(/* webpackChunkName: "Course" */ '../views/Course.vue')
  },
  // routes for login in with Google on desktop
  {
    path: '/desktopLogin',
    name: 'desktopLogin',
    component: () =>
      import(
        /* webpackChunkName: "desktopLogin" */ '../views/DesktopLogin/WebHandler.vue'
      )
  },
  {
    path: '/login',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/DesktopLogin/Login.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
