<template>
  <div>
    <label class="block mb-0.5 text-sm">{{label}}</label>
    <input
      v-bind:placeholder="placeholder"
      v-bind:type="inputType"
      @input="handleInput"
    >
  </div>
</template>

<script>
export default {
  data() {
    return {
      content: this.value
    }
  },
  methods: {
    handleInput (e) {
      this.$emit('input', e.target.value)
    }
  },
  props: {
    label: String,
    inputType: String,
    placeholder: String,
    value: [String, Number]
  }
}
</script>
