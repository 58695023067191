<template>
  <div class="modal w-screen"
    v-bind:class="{
      open: open
    }">
    <div class="bg-white border-b border-gray-200 flex h-7 rounded-t-md w-full">
      <div class="align-top flex-grow ml-1.5 mt-1.5">
        <div class="font-semibold leading-none text-lg">Profile</div>
        <div class="leading-none text-gray-500 text-sm">b</div>
      </div>

      <img class="flex-none px-2.5 py-2.5"
        src="@/assets/icons/Info.svg"
        v-on:click="$router.push({query: { sidebar: 'open' }})"/>
    </div>

    <div class="p-1">
      {{loggedInUser}}
      <button class="border border-gray-300 bg-white px-2 py-0.5 rounded-sm text-red-500"
        v-on:click="signout()">Sign out</button>
    </div>
  </div>
</template>

<script>
import { cfaSignOut } from 'capacitor-firebase-auth'
import {
  Plugins,
  StatusBarStyle
} from '@capacitor/core'

const { StatusBar } = Plugins

export default {
  computed: {
    loggedInUser () {
      return this.$store.state.Auth.user
    },
    open () {
      return this.$route.query.modal === 'profile'
    }
  },
  mounted () {
    StatusBar.setStyle({
      style: StatusBarStyle.Light
    })
  },
  methods: {
    signout () {
      this.$router.push({
        query: {}
      })

      setTimeout(() => {
        cfaSignOut().subscribe()
      }, 300)
    }
  }
}
</script>

<style lang="sass" scoped>
.modal
  height: calc(100vh - 100px)
  transform: translate3d(0, 0, 0)

  &.open
    transform: translate3d(0, calc(-100vh + 32px), 0)
    transform: translate3d(0, calc(-100vh + env(safe-area-inset-top) + 32px), 0)

@media (min-width: 768px)
  .modal
    max-height: 600px
    max-width: 600px
    margin: 0 calc(50vw - 300px)
</style>
