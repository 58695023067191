<template>
  <div />
</template>

<script>
import {
  Plugins
  /* PushNotification,
  PushNotificationToken,
  PushNotificationActionPerformed */
} from '@capacitor/core'

const { PushNotifications } = Plugins

export default {
  computed: {
    deviceInfo () {
      return this.$store.state.UI.device.info
    }
  },
  methods: {
    setupPushNotifications () {
      const self = this

      PushNotifications.requestPermission().then(result => {
        if (result.granted) {
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register()
        } else {
          // Show some error
        }
      })

      // On success, we should be able to receive notifications
      PushNotifications.addListener('registration',
        (token) => {
          self.$store.commit('UI/setNotificationsToken', token.value)
          // alert('Push registration success, token: ' + token.value)
        }
      )

      // Some issue with our setup and push will not work
      PushNotifications.addListener('registrationError',
        (error) => {
          console.log('Error on registration: ' + JSON.stringify(error))
        }
      )

      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener('pushNotificationReceived',
        (notification) => {
          alert('Push received: ' + JSON.stringify(notification))
        }
      )

      // Method called when tapping on a notification
      PushNotifications.addListener('pushNotificationActionPerformed',
        (notification) => {
          alert('Push action performed: ' + JSON.stringify(notification))
        }
      )
    }
  },
  mounted () {
    if (this.deviceInfo !== undefined) {
      if (this.deviceInfo.platform !== 'web') {
        this.setupPushNotifications()
      }
    }
  },
  watch: {
    deviceInfo (newVal) {
      if (newVal !== undefined && newVal.platform !== 'web') {
        this.setupPushNotifications()
      }
    }
  }
}
</script>
