<template>
  <div class="absolute duration-300 text-xs transition w-full"
        id="chat-messages">
      <div class="bg-beige border-b border-gray-200 w-full" style="height: 400px"></div>
      <div class="message mt-1 px-1.5"
        v-for="message in chatMessages"
        v-bind:key="message.id"
        v-bind:class="{
          'sent-by-me': message.user === loggedInUser.uid
        }">
        <!-- <div class="align-bottom bg-gray-300 h-3 inline-block rounded-full w-3"
          v-if="message.user !== loggedInUser.uid"></div> -->

        <div class="bg-white border border-gray-200 inline-block message-bubble ml-0.5 px-2 py-1.5"
          v-html="message.content">
          <!-- <div class="-mb-0.5 text-gray-400 text-xs"
          v-if="message.user !== loggedInUser.uid">John Doe</div> -->
        </div>
      </div>
      <div id="end-of-messages" />
  </div>
</template>

<script>
import { db } from '@/firebase/app'

export default {
  computed: {
    chatId () {
      return this.$route.query.chat
    },
    loggedInUser () {
      return this.$store.state.Auth.user
    }
  },
  data() {
    return {
      chatMessages: []
    }
  },
  watch: {
    chatId () {
      if (this.chatId !== undefined) {
        this.$bind('chatMessages', db.collection('chat-messages').where('conversation', '==', this.chatId).orderBy('timestamp', 'asc'))
      } else {
        const self = this
        setTimeout(() => {
          self.$unbind('chatMessages')
        }, 300)
      }
    }
  }
}
</script>

<style lang="sass" scoped>
#chat-messages
  height: calc(100vh - 56px)
  height: calc(100vh - env(safe-area-inset-top) - 56px)
  max-height: calc(100vh - 56px)
  max-height: calc(100vh - env(safe-area-inset-top) - 56px)
  -webkit-overflow-scrolling: touch
  overflow-y: scroll
  padding-bottom: 120px
  padding-bottom: calc(env(safe-area-inset-bottom) + 120px)
  transition-delay: 0

  &.keyboard-opened
    height: calc(100vh - 56px - 56px - 48px)
    height: calc(100vh - env(safe-area-inset-top) - 56px - 48px - 56px)
    max-height: calc(100vh - 56px - 48px - 56px)
    max-height: calc(100vh - env(safe-area-inset-top) - 56px - 48px - 56px)

  .message-bubble
    border-radius: 10px 10px 10px 0
    max-width: 240px

  .message.sent-by-me
    @apply flex flex-row-reverse

    .message-bubble
      /* stylelint-disable-next-line */
      border-radius: 10px 10px 0 10px
      margin-left: auto
      @apply bg-black text-white
</style>
