<template>
  <div id="auth-page"
    class="bg-beige fixed left-0 mt-safe top-0 w-screen z-50"
    v-if="user === undefined || user === null">
    <div id="auth-statusbar-cover"
      class="bg-white fixed top-0 w-screen"></div>

    <div class="bg-white border-b border-gray-200 h-7 w-screen">
      <img class="h-5 mx-auto pt-1.5" src="@/assets/eddi_logo.svg" />
    </div>

    <div class="h-full mx-auto pt-3 relative" style="max-width: 350px">
      <LogIn
        v-bind:page="page"
        v-on:continue-with-email="continueWithEmail" />

      <PasswordForm
        v-bind:authMethodsForEmail="authMethodsForEmail"
        v-bind:email="email"
        v-bind:page="page"
        v-on:back="page = 'index'" />

      <img class="absolute bottom-0 w-full"
        src="@/assets/illustration/pablo-education.svg" />
    </div>
  </div>
</template>

<script>
import { firebase } from '@/firebase/app'
import LogIn from './LogIn'
import PasswordForm from './PasswordForm'

export default {
  components: {
    LogIn,
    PasswordForm
  },
  computed: {
    courses () {
      return this.$store.state.Auth.courses
    },
    user () {
      return this.$store.state.Auth.user
    }
  },
  data: function() {
    return {
      authMethodsForEmail: [],
      email: '',
      page: 'index'
    }
  },
  methods: {
    changeState (state) {
      this.state = state
    },
    continueWithEmail (payload) {
      this.email = payload.email
      this.authMethodsForEmail = payload.authMethodsForEmail
      this.page = 'email'
    },
    loginWithEmail () {
      firebase.auth().signInWithEmailAndPassword(this.email, this.password)
        .then((user) => {
          // Signed in
        })
        .catch((error) => {
          console.log(error)
          alert('wrong password')
        })
    }
  },
  watch: {
    courses (newVal) {
      if (newVal !== undefined && newVal !== null) {
        if (newVal.length > 0) {
          if (this.$route.params.course_id !== newVal[0].id) {
            this.$router.push('/course/' + newVal[0].id)
          }
        }
      }
    }
  }
}
</script>

<style lang="sass" scoped >
#auth-statusbar-cover
  height: 0
  height: env(safe-area-inset-top)

#auth-page
  height: 100vh
  height: calc(100vh - env(safe-area-inset-top))
  transition: opacity 0.3s
</style>
