<template>
  <div />
</template>

<script>
import { db } from '@/firebase/app'

export default {
  computed: {
    device () {
      return this.$store.state.UI.device
    },
    user () {
      return this.$store.state.Auth.user
    }
  },
  methods: {
    assignDeviceToUser () {
      const userRef = db.collection('users').doc(this.$store.state.Auth.user.uid)
      userRef.update({
        devices: [
          ...this.$store.state.Auth.user.devices,
          this.device.info.uuid
        ]
      })
    },
    registerDevice () {
      const deviceRef = db.collection('devices').doc(this.device.info.uuid)
      deviceRef.set({
        ...this.device,
        // created_at: new Date(),
        updated_at: new Date()
      })
    },
    unnasignDeviceFromUser (user) {
      const userRef = db.collection('users').doc(user.uid)
      const devices = [...user.devices]
      for (let i = 0; i < devices.length; i++) {
        if (devices[i] === this.device.info.uuid) {
          devices.splice(i, 1)
          break
        }
      }

      userRef.update({
        devices: devices
      })
    }
  },
  mounted () {
    if (this.device.languageCode.value !== null && this.user !== undefined) {
      this.registerDevice()
    }
  },
  watch: {
    device: {
      deep: true,
      handler: function () {
        if (this.user !== undefined) {
          this.registerDevice()
        }
      }
    },
    user (newVal, oldVal) {
      if (newVal === null) { // logged out
        this.unnasignDeviceFromUser(oldVal)
      } else {
        if (!newVal.devices.includes(this.device.info.uuid)) {
          this.assignDeviceToUser()
        }
      }
    }
  }
}
</script>
