<template>
  <div></div>
</template>

<script>
export default {
  data() {
    return {
      shouldKeepOpened: false
    }
  },
  mounted () {
    const self = this

    window.addEventListener('keyboardWillShow', (e) => {
      // editor
      const chatInput = document.getElementById('chat-input')
      chatInput.classList.add('keyboard-opened')
      chatInput.style.transform = 'translate3d(0, -' + (e.keyboardHeight).toString() + 'px, 0)'

      const chatMessages = document.getElementById('chat-messages')
      chatMessages.classList.add('keyboard-opened')
      chatMessages.style.transform = 'translate3d(0, -' + (e.keyboardHeight).toString() + 'px, 0)'

      self.shouldKeepOpened = true

      /* chatMessages.scroll({
        top: chatMessages.scrollHeight,
        behavior: 'smooth'
      }) */

      setTimeout(() => {
        self.shouldKeepOpened = false
      }, 2000)
      //

      if (self.$route.query.modal === undefined) {
        return
      }

      const joinCourseModal = document.getElementById('join-course-modal')
      if (joinCourseModal.classList.contains('first')) {
        joinCourseModal.style.transform = 'translate3d(0, calc(-192px - ' + (e.keyboardHeight).toString() + 'px), 0)'
      } else {
        joinCourseModal.style.transform = 'translate3d(0, calc(-316px - ' + (e.keyboardHeight).toString() + 'px), 0)'
      }
    })

    window.addEventListener('keyboardWillHide', (e) => {
      const chatInput = document.getElementById('chat-input')
      chatInput.classList.remove('keyboard-opened')
      chatInput.style.transform = 'translate3d(0, 0, 0)'

      const chatMessages = document.getElementById('chat-messages')
      chatMessages.style.transform = 'translate3d(0, 0, 0)'
      chatMessages.classList.remove('keyboard-opened')

      // Course Creation Modal
      const joinCourseModal = document.getElementById('join-course-modal')
      joinCourseModal.removeAttribute('style')
    })

    window.addEventListener('keyboardDidShow', (e) => {
      //
    })
  }
}
</script>
