<template>
  <vue-dropzone
    class="absolute opacity-0 pointer-events-none"
    ref="filesVueDropzone"
    id="filesInput-dropzone"
    :options="dropzoneOptions"
    @vdropzone-file-added="dropzoneFileAdded"
    @vdropzone-removed-file="dropzoneFileRemoved">
  </vue-dropzone>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
  components: {
    vueDropzone: vue2Dropzone
  },
  data() {
    return {
      dropzoneFiles: [],
      dropzoneOptions: {
        acceptedFiles: '.pdf, .csv, .xls, .zip, .rar, .ppt',
        addRemoveLinks: true,
        url: 'https://httpbin.org/post',
        thumbnailWidth: 150,
        maxFilesize: 50,
        headers: { 'My-Awesome-Header': 'header value' },
        dictRemoveFile: '<img src="/icons/Close_white.svg" />'
      }
    }
  },
  methods: {
    dropzoneFileAdded (file) {
      const chatTextInput = document.getElementsByClassName('editor__content')[0]
      chatTextInput.style.display = 'none'
      console.log(file)
      this.dropzoneFiles.push(file)
    },
    dropzoneFileRemoved (file) {
      const fileIndex = this.dropzoneFiles.indexOf(file)
      this.dropzoneFiles.splice(fileIndex, 1)

      if (this.dropzoneFiles.length === 0) {
        const chatTextInput = document.getElementsByClassName('editor__content')[0]
        chatTextInput.removeAttribute('style')
      }
    }
  }
}
</script>

<style lang="sass">
#filesInput-dropzone
  @apply border-0 h-14 p-0 w-full

  &.dropzone
    height: 80px !important
    min-height: unset !important

  &.dz-started
    opacity: 1 !important
    pointer-events: auto !important
    position: relative !important

  .dz-preview
    height: 64px
    margin: 8px !important
    min-height: 64px !important
    max-width: 240px
    @apply border flex-grow inline-block rounded-md text-gray-800

    &:hover
      .dz-details
        // background-color: #F0F0F0 !important

  .dz-details
    background-color: #FFF !important
    color: #121212 !important
    padding: 12px !important
    @apply rounded-md

    .dz-filename
      @apply font-semibold text-sm

    .dz-size
      @apply absolute
      font-size: 12px !important
      font-weight: 300
      position: absolute !important
      top: 32px
      width: calc(100% - 24px)

  .dz-success
    .dz-remove
      background: #424242 !important
      border-radius: 50%
      color: #121212 !important
      height: 24px
      margin-left: 0 !important
      padding: 6px !important
      opacity: 0 !important
      top: -12px !important
      right: -12px !important
      width: 24px

      img
        height: 8px
        min-width: 8px
        pointer-events: none
        width: 8px

  .vue-dropzone:hover
    background-color: transparent !important

    .dz-success
      .dz-remove
        opacity: 1 !important
</style>
