<template>
  <div id="chat-input"
    class="absolute bg-white border-b border-gray-200 w-full md:mb-1.5 md:mx-1.5 md:border md:rounded-md">
    <div class="border-b border-gray-200 flex pl-1.5 w-full md:rounded-t-md">
      <FilesInput />

      <editor-content class="editor__content outline-none text-black w-full" :editor="editor" />
      <div class="bg-gray-200 duration-300 flex-none h-3 mx-2 my-2 rounded-full transform transition w-3"
        v-bind:class="{
          'bg-blue-500 -rotate-90': messageNotEmpty
        }"
        v-on:click="send()">
        <img class="max-w-none -ml-0.5 -mt-0.5 w-4" src="@/assets/icons/Send.svg" />
      </div>
    </div>

    <InputToolbar :editor="editor" />
    <div class="bg-white w-full"
      id="chat-bottom-cover"></div>
  </div>
</template>

<script>
import { Editor, EditorContent } from 'tiptap'
import InputToolbar from './InputToolbar'
import FilesInput from './FilesInput'

import {
  Bold,
  BulletList,
  Italic,
  ListItem,
  OrderedList,
  Placeholder
} from 'tiptap-extensions'

import { db } from '@/firebase/app'
import { Plugins } from '@capacitor/core'

const { Keyboard } = Plugins

export default {
  beforeDestroy() {
    // Always destroy your editor instance when it's no longer needed
    this.editor.destroy()
  },
  components: {
    EditorContent,
    FilesInput,
    InputToolbar
  },
  computed: {
    messageNotEmpty () {
      const justText = this.message.replace(/<[^>]+>/g, '')

      return justText.length > 0
    }
  },
  data() {
    return {
      editor: new Editor({
        content: '',
        extensions: [
          new Bold(),
          new BulletList(),
          new Italic(),
          new ListItem(),
          new OrderedList(),
          new Placeholder({
            emptyEditorClass: 'is-editor-empty',
            emptyNodeClass: 'is-empty',
            emptyNodeText: 'Write something…',
            showOnlyWhenEditable: true,
            showOnlyCurrent: true
          })
        ],
        onUpdate: ({ getHTML }) => {
          // get new content on update
          this.message = getHTML()
        }
      }),
      oldMessage: '',
      message: ''
    }
  },
  methods: {
    hideKeyboard() {
      Keyboard.hide()
    },
    send () {
      const self = this

      const chatInput = document.getElementById('chat-input')
      const htmlInput = chatInput.getElementsByClassName('ProseMirror')[0]

      htmlInput.focus()

      self.oldMessage = self.message
      self.message = ''

      db.collection('chat-messages').add({
        user: self.$store.state.Auth.user.uid,
        content: self.oldMessage,
        conversation: self.$route.query.chat,
        timestamp: new Date(),
        type: 'direct_message'
      })
        .then(() => {
          self.editor.setContent('')
          self.oldMessage = ''
          const chatMessages = document.getElementById('chat-messages')
          chatMessages.scroll({
            top: chatMessages.scrollHeight,
            behavior: 'smooth'
          })
        })
        .catch((error) => {
          self.message = self.oldMessage
          self.oldMessage = ''
          console.error('Error writing document: ', error)
        })
    }
  }
}
</script>

<style lang="sass">
#chat-input
  bottom: -1px
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25)
  transform: translate3d(0, 0, 0)
  transition: all 0.3s ease

  &.keyboard-opened
    bottom: -1px

    #chat-bottom-cover
      height: 0

  .editor p.is-editor-empty:first-child::before
    color: #aaa
    content: attr(data-empty-text)
    float: left
    font-style: italic
    height: 0
    pointer-events: none

  /* stylelint-disable-next-line */
  .ProseMirror
    @apply py-2 outline-none text-black

  .is-active
    @apply bg-gray-300 opacity-100

  .ProseMirror p.is-editor-empty:first-child::before
    content: attr(data-empty-text)
    float: left
    height: 0
    pointer-events: none
    @apply text-gray-300

#chat-bottom-cover
  height: 0
  height: env(safe-area-inset-bottom)

@media (min-width: 768px)
  #chat-input
    box-shadow: none
    width: calc(100% - 24px)
</style>
