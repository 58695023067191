import { db } from '@/firebase/app'
import { vuexfireMutations, firestoreAction } from 'vuexfire'

export default {
  namespaced: true,
  state: {
    courses: undefined,
    user: undefined
  },
  mutations: {
    ...vuexfireMutations,
    setUser(state, user) {
      state.user = user
    }
  },
  actions: {
    loadUser: firestoreAction(({ bindFirestoreRef }, user) => {
      bindFirestoreRef('user', db.collection('users').doc(user.uid))
      bindFirestoreRef(
        'courses',
        db.collection('courses').where('members', 'array-contains', user.uid)
      )
    }),
    removeUser: firestoreAction(
      ({ commit, state, unbindFirestoreRef }, user) => {
        if (state.user === undefined) {
          commit('setUser', null)
        } else {
          unbindFirestoreRef('user')
        }
      }
    )
  }
}
