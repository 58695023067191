<template>
  <editor-menu-bar class="bg-white h-7 px-1.5 py-1 md:bg-gray-100 md:rounded-b-md md:py-0 md:h-5" :editor="editor" v-slot="{ commands, isActive }">
    <div class="menubar">
      <div class="flex">
        <div class="flex-none">
          <!-- <div class="h-full inline-block p-1 w-6">
            <img class="h-4" src="@/assets/icons/Thunder.svg" />
          </div> -->
          <!-- <div class="h-full inline-block px-1 py-1.5 w-6">
            <img src="@/assets/icons/Image.svg" />
          </div> -->

          <button
            class="align-top font-bold menubar__button mr-1 opacity-50 py-1 px-1.5 rounded-md text-center transition w-5 focus:ring-0 focus:outline-none hover:opacity-100"
            @click="openFileInput()"
          >
            <img style="margin-top: 6px" src="@/assets/icons/Clip.svg" />
          </button>

          <button
            class="align-top font-bold menubar__button mr-1 opacity-50 py-1 px-1.5 rounded-md text-center w-5 focus:ring-0 focus:outline-none hover:opacity-100"
            @click="openImageInput()"
          >
            <img style="margin-top: 6px" src="@/assets/icons/Image.svg" />
          </button>

          <button
            class="align-top font-bold menubar__button h-4 mr-1 mt-0.5 opacity-50 px-1 rounded-md text-center w-4 focus:ring-0 focus:outline-none hover:opacity-100"
            :class="{ 'is-active': isActive.bold() }"
            @click="commands.bold"
          >
            B
          </button>

          <button
            class="align-top font-serif menubar__button h-4 mr-1 mt-0.5 opacity-50 px-1 rounded-md text-center w-4 focus:ring-0 focus:outline-none hover:opacity-100"
            :class="{ 'is-active': isActive.italic() }"
            @click="commands.italic"
            >
            <span class="italic">I</span>
          </button>

          <button
            class="align-top font-bold menubar__button h-4 mr-1 mt-0.5 opacity-50 px-1 rounded-md text-center w-4 focus:ring-0 focus:outline-none hover:opacity-100"
            :class="{ 'is-active': isActive.bullet_list() }"
            @click="commands.bullet_list"
          >
            <img src="@/assets/icons/Unordered_list.svg" />
          </button>

          <button
            class="align-top font-bold menubar__button h-4 mr-1 mt-0.5 opacity-50 px-1 rounded-md text-center w-4 focus:ring-0 focus:outline-none hover:opacity-100"
            :class="{ 'is-active': isActive.ordered_list() }"
            @click="commands.ordered_list"
          >
            <img src="@/assets/icons/Ordered_list.svg" />
          </button>
        </div>

        <div class="flex-grow"></div>

        <div class="flex-none md:hidden">
          <div class="h-full inline-block pl-1.5 py-1.5 w-6"
            v-on:click="hideKeyboard()">
            <img src="@/assets/icons/Keyboard.svg" />
          </div>
        </div>

        <input accept=".pdf, .csv, .doc, .docx"
          id="message-file-input" class="hidden" multiple="multiple" type="file"
          @change="handleFileInputChange"/>
      </div>
    </div>
  </editor-menu-bar>
</template>

<script>
import { EditorMenuBar } from 'tiptap'

export default {
  components: {
    EditorMenuBar
  },
  methods: {
    handleFileInputChange (e) {
      const files = e.target.files
      console.log(files)
    },
    openFileInput () {
      const dropzone = document.getElementById('filesInput-dropzone')
      dropzone.click()
    },
    openImageInput () {
      const dropzone = document.getElementById('imagesInput-dropzone')
      dropzone.click()
    }
  },
  props: {
    editor: Object
  }
}
</script>
