<template>
  <div class="absolute left-0 top-3 w-full z-40"
    id="auth-login-view"
    v-bind:class="{
      'moved': page === 'email',
      'opacity-0': user !== null && user !== undefined
    }">

    <div class="">
      <div>Sign in to your account or create one</div>
      <div class="mb-4 text-gray-400 text-xs">If you already have an account we’ll log you in, else, we’ll create an account for you.</div>

      <basicInput
        label="email"
        placeholder="eddi@gmail.com"
        inputType="email"
        v-model="email" />

      <button
        class="bg-gray-300 mb-2 mt-2 pointer-events-none py-1 rounded-md text-center text-gray-400 w-full"
        v-bind:class="{
          active: emailValid
        }"
        v-on:click="checkAuthenticationMethods()">Continue with email</button>

      <div class="relative text-center text-gray-400 text-xs w-full">
        <hr class="absolute top-1 w-full">
        <span class="bg-beige px-1 relative z-10">or continue with</span>
      </div>

      <button class="bg-white border-1 border-black font-semibold font-sans mt-2 py-1 rounded-md text-black text-center w-full"
        v-on:click="socialLogin('apple')">
        <img class="h-1.5 inline mr-0.5"
          src="@/assets/image/apple.svg"
          style="margin-top: -4px" /> Sign in with Apple
      </button>

      <div class="flex justify-center mt-2">
        <div class="p-2">
          <div class="bg-white border-1 border-gray-200 h-5 rounded-full text-center w-5"
            v-on:click="socialLogin('google')">
            <img class="h-3"
              src="@/assets/image/google_logo.svg"
              style="margin-left: 7px;
                margin-top: 7px" />
          </div>
        </div>
        <div class="ml-1 p-2">
          <div class="bg-white border-1 border-gray-200 h-5 rounded-full text-center w-5"
            v-on:click="socialLogin('facebook')">
            <img class="h-3 mt-1 mx-1.5"
              src="@/assets/image/facebook.svg"
              />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { firebase } from '@/firebase/app'
import basicInput from '@/components/Forms/input'
import { cfaSignIn } from 'capacitor-firebase-auth'

export default {
  components: {
    basicInput
  },
  computed: {
    emailValid () {
      const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return regex.test(String(this.email).toLowerCase())
    },
    user () {
      return this.$store.state.Auth.user
    }
  },
  data() {
    return {
      email: ''
    }
  },
  methods: {
    checkAuthenticationMethods () {
      firebase.auth().fetchSignInMethodsForEmail(this.email).then((methods) => {
        if (methods.includes('password') || methods.length === 0) {
          this.$emit('continue-with-email', {
            authMethodsForEmail: methods,
            email: this.email
          })
        } else {
          alert('you created your acccount using ' + methods[0] + ' please login with that same method     ')
        }
      })
    },
    socialLogin (provider) {
      if (provider === 'google' && window.todesktop) {
        /* eslint-disable no-undef */
        gapi.load('auth2', () => {
          gapi.auth2.init({
            client_id: '913253025829-8do5pc75sd61g429fei9g8tsm79s42qh.apps.googleusercontent.com',
            ux_mode: 'redirect', // IMPORTANT!
            redirect_uri: 'https://app.eddi.org/desktopLogin'
          })
            .then(() => {
              const auth = gapi.auth2.getAuthInstance()
              auth.signIn()
            })
        })
      } else {
        cfaSignIn(provider + '.com').subscribe(
          (user) => {
          // alert(user.email)
          // alert(user.displayName)
          }
        )
      }
    }
  },
  props: {
    page: String
  }
}
</script>

<style scoped lang="sass">
#auth-login-view
  height: 100vh
  height: calc(100vh - env(safe-area-inset-top))
  transform: translate3d(0, 0, 0)
  transition: all 0.3s ease-in-out
  will-change: transform

  &.moved
    transform: translate3d(-100vw, 0, 0)

  button
    transition: all 0.3s ease

    &.active
      color: #fff
      /* stylelint-disable-line */
      @apply bg-blue-600 pointer-events-auto
</style>
