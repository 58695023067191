<template>
  <div class="bg-white flex flex-col fixed h-screen pt-safe text-white top-0 w-10 z-40"
    id="sidebar"
    v-bind:style="{
      backgroundColor: sidebarColor
    }"
    v-if="$route.path !== '/'">

    <div class="absolute bg-beige h-screen opacity-0 pointer-events-none top-0 w-screen z-40"
      id="sidebar-overlay"
      style="background-image: url('/lines_thick.svg')"
      v-bind:class="{
        'open': showOverlay
      }"
      v-on:click="$router.push({query: {}})" />

    <div class="flex-grow">
      <div class="course cursor-pointer mb-2"
        v-for="course in courses"
        v-bind:key="course.id"
        v-on:click="$router.push('/course/' + course.id)">
        <div class="bg-white border border-gray-300 h-6 mx-2 my-1 rounded-md w-6">
        </div>
        <div class="mx-0.5 text-center text-xs">{{course.title}}</div>
      </div>

      <div class="cursor-pointer mt-2 mx-2"
        v-on:click="openNewCourseModal()">
        <div id="add-course" class="h-6 p-1.5 rounded-lg w-6">
          <img class="opacity-50 pt-0.5 w-full hover:opacity-100" src="@/assets/icons/Add_Course.svg" />
        </div>
      </div>
    </div>

    <div class="bg-gray-100 border border-gray-300 h-6 mx-2 my-2 text-center w-6"
      id="sidebar-profile-pic"
      v-on:click="openProfile()">
      <img class="h-2 mx-auto w-2"
        style="margin-top: 14px"
        src="@/assets/icons/User.svg" />
    </div>

  </div>
</template>

<script>
export default {
  computed: {
    activeCourse () {
      const courses = this.$store.state.Auth.courses
      if (courses === undefined) {
        return null
      }
      return courses.filter(course => course.id === this.$route.params.course_id)[0]
    },
    sidebarColor () {
      if (this.activeCourse !== undefined && this.activeCourse !== null) {
        return this.activeCourse.default_color
      }
      return '#FFF'
    },
    courses () {
      return this.$store.state.Auth.courses
    },
    showOverlay () {
      return this.$route.query.sidebar !== undefined
    }
  },
  methods: {
    openNewCourseModal () {
      this.$router.push({
        query: {
          modal: ''
        }
      })
      const self = this

      let time = 300

      if (window.innerWidth > 768) {
        time = 0
      }

      setTimeout(() => {
        self.$router.push({
          query: {
            modal: 'new-course'
          }
        })
      }, time)
    },
    openProfile () {
      const originalQuery = { ...this.$route.query }

      delete originalQuery.sidebar

      // close sidebar
      this.$router.push({
        query: {
          sidebar: 'just-show-overlay',
          modal: 'just-show-overlay'
        }
      })

      let time = 300

      if (window.innerWidth > 768) {
        time = 0
      }

      const self = this
      setTimeout(() => {
        self.$router.push({
          query: {
            originalQuery,
            modal: 'profile',
            uid: self.$store.state.Auth.user.uid
          }
        })
      }, time)
    }
  },
  watch: {
    /* showOverlay (newVal) {
      const statusbarCover = document.getElementById('topbar-statusbarcover')
      if (newVal) {
        // StatusBar.hide()
        statusbarCover.style.zIndex = '0'
      } else {
        // StatusBar.show()
        const self = this
        setTimeout(() => {
          if (!self.showOverlay) {
            statusbarCover.style.zIndex = '40'
          }
        }, 500)
      }
    } */
  }
}
</script>

<style lang="sass" scoped>
html.todesktop #sidebar
  padding-top: 32px

#sidebar
  left: -80px
  padding-bottom: 0
  padding-bottom: env(safe-area-inset-bottom)
  -webkit-app-region: drag

#sidebar-overlay
  left: 80px
  transition: opacity 0.3s ease

  &.open
    opacity: 0.95
    /* stylelint-disable-next-line */
    @apply pointer-events-auto

#sidebar-profile-pic
  border-radius: 18px

#add-course
  background-color: rgba(0, 0, 0, 0.3)

@media (min-width: 768px)
  #sidebar
    left: 0

  #sidebar-overlay
    display: none
</style>
