// import { db } from '@/firebase/app'
// import { vuexfireMutations, firestoreAction } from 'vuexfire'

import Languages from '@/utils/Languages/index'
import { Plugins } from '@capacitor/core'

const { Device } = Plugins

export default {
  namespaced: true,
  state: {
    selectedLanguage: Languages.en,
    device: {
      info: undefined,
      notificationsToken: null,
      languageCode: {
        value: undefined
      }
    }
  },
  mutations: {
    async loadDeviceInfo(state) {
      const info = await Device.getInfo()
      state.device.info = info
      const languageCode = await Device.getLanguageCode()
      state.device.languageCode = languageCode

      if (Languages[languageCode.value.split('-')[0]] !== undefined) {
        state.selectedLanguage = Languages[languageCode.value.split('-')[0]]
      } else {
        state.selectedLanguage = Languages.en
      }
    },
    setNotificationsToken(state, token) {
      state.device.notificationsToken = token
    }
  },
  actions: {}
}
