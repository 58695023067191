<template>
  <div id="join-course-modal"
    class="modal rounded-lg"
    v-bind:class="[resultingStep]">

    <JoinOrCreateSelection
      v-if="$route.query.modal === 'new-course' && step === 'first'"
      v-on:create-course="step = 'create'" />

    <CreateCourse
      v-if="$route.query.modal === 'new-course' && step === 'create' " />
  </div>
</template>

<script>
import CreateCourse from './CreateCourse'
import JoinOrCreateSelection from './JoinOrCreateSelection'

export default {
  components: {
    CreateCourse,
    JoinOrCreateSelection
  },
  computed: {
    resultingStep () {
      if (this.$route.query.modal === 'new-course') {
        return this.step
      } else {
        return null
      }
    }
  },
  data() {
    return {
      step: 'first'
    }
  },
  watch: {
    '$route.query.modal': {
      handler: function () {
        if (this.$route.query.modal === undefined) {
          this.step = 'first'
        }
      }
    }
  }
}
</script>

<style scoped lang="sass">
.modal
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25)
  padding-bottom: 0
  padding-bottom: env(safe-area-inset-bottom)
  transform: translate3d(0, 0, 0)

  &.first
    transform: translate3d(0, -312px, 0)
    transform: translate3d(0, calc(-312px - env(safe-area-inset-bottom)), 0)

  &.create
    transform: translate3d(0, -532px, 0)
    transform: translate3d(0, calc(-532px - env(safe-area-inset-bottom)), 0)

@media (min-width: 768px)
  .modal
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25)
    left: calc(50vw - 240px)
    max-width: 480px

    &.first
      transform: translate3d(0, calc(-100vh + 100px), 0)
      transform: translate3d(0, calc(-100vh + 100px - env(safe-area-inset-bottom)), 0)

    &.create
      transform: translate3d(0, calc(-100vh + 100px), 0)
      transform: translate3d(0, calc(-100vh + 100px - env(safe-area-inset-bottom)), 0)
</style>
