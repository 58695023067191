import Vue from 'vue'
import Vuex from 'vuex'
import { vuexfireMutations } from 'vuexfire'

// Modules
import Auth from './modules/Auth'
import Courses from './modules/Courses'
import UI from './modules/UI'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {
    ...vuexfireMutations
  },
  actions: {},
  modules: {
    Auth,
    Courses,
    UI
  }
})
