<template>
  <div class="relative">
    <div class="mt-2.5 px-2">
      <div class="font-bold text-lg">Add Course</div>
      <div class="text-gray-400 text-xs">Log into an existing course</div>
    </div>

    <div class="absolute cursor-pointer right-1 -top-1 hover:opacity-50"
      v-on:click="$router.push({query: {}})">
      <img class="p-2 w-6" src="@/assets/icons/Close.svg" />
    </div>

    <div class="mt-4 px-2">
      <div class="font-bold text-md">Course ID or link</div>
      <div class="flex">
        <input class="flex-grow rounded-md"
          placeholder="A9MCC or https://eddi.org/course/A9MCC"
          type="text"
          v-model="courseCode">
        <button class="bg-gray-300 flex-none h-6 ml-2 pointer-events-none rounded-md w-7"
          v-bind:class="{
            'bg-blue-500 pointer-events-auto': validCourseCode
          }"
          v-on:click="addCourse()">
          <img class="ml-2.5" src="@/assets/icons/Arrow_right_white.svg" />
        </button>
      </div>
      <span class="ml-1 text-red-500 text-sm"
        v-if="courseCode.length > 0 && !validCourseCode">Invalid course code</span>
    </div>

    <div class="mb-0.5 mt-5 text-center text-gray-400 text-sm w-full">or start a new one</div>

    <button id="create-course-button"
      class="block border border-blue-500 h-6 mb-4 mx-auto pl-1.5 rounded-md text-blue-500 text-left"
      v-on:click="$emit('create-course')">
      Create a course <img class="float-right mr-2 mt-1" src="@/assets/icons/Arrow_right_blue.svg" />
    </button>
  </div>
</template>

<script>
import { db } from '@/firebase/app'

export default {
  computed: {
    validCourseCode () {
      const code = this.courseCode.replace('https://eddi.org/', '')

      const regex = /^[1-9a-zA-Z]+$/
      if (code.length === 6 && code.match(regex)) {
        return true
      } else {
        return false
      }
    }
  },
  data() {
    return {
      courseCode: ''
    }
  },
  methods: {
    async addCourse () {
      const courseRef = db.collection('courses').doc(this.courseCode)
      const doc = await courseRef.get()
      if (!doc.exists) {
        alert('wrong course ID, that course does not exists')
      } else {
        const userID = this.$store.state.Auth.user.uid
        if (doc.data().members.includes(userID)) {
          alert('you are already in that course')
          return ''
        }

        const self = this
        courseRef.update({
          members: [
            ...doc.data().members,
            userID
          ]
        }).then(() => {
          self.$router.push({
            path: '/course/' + self.courseCode
          })
        })
      }
    }
  }
}
</script>

<style scoped lang="sass">
#create-course-button
  width: 208px
</style>
